type Aria = 'disclaimer' | 'author' | 'meta' | 'content';

export function getAriaId(feedItemId: string, aria: Aria) {
  return `feed-item-${aria}-${feedItemId}` as const;
}

export function getDescribedBy(feedItemId: string) {
  return [getAriaId(feedItemId, 'content')].join(' ');
}

export function getLabelledBy(feedItemId: string) {
  return [
    getAriaId(feedItemId, 'disclaimer'),
    getAriaId(feedItemId, 'author'),
    getAriaId(feedItemId, 'meta'),
  ].join(' ');
}
