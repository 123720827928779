import React, { useEffect } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';

import { Show } from 'wui/Show';
import { Popover } from 'wui/Popover';
import { Typography } from 'wui/Typography';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';
import {
  Tag as TagIcon,
  Plus as PlusIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Topic } from '../../Topic';

import { TopicsForm } from './TopicsForm';
import { TopicsDialog } from './TopicsDialog';

interface IProps extends React.ComponentProps<typeof TopicsForm> {}

export function TopicsSelect(props: IProps) {
  const { groupId, value } = props;

  const { t } = useTranslation();
  const { topics$ } = useController();
  const { isMobile } = useEnvironment();

  const [isOpen, setIsOpen] = React.useState(false);

  const preview = value.slice(0, 2);
  const rest = value.slice(2);

  useEffect(() => {
    if (isOpen) {
      topics$.fetch(groupId);
    }
  }, [groupId, isOpen]);

  return (
    <>
      <Popover
        isOpen={!isMobile && isOpen}
        onClose={handleClose}
        minWidth={320}
        placement="top-start"
        content={<TopicsForm height="300px" {...props} />}
      >
        {preview.length ? (
          <Stack wrap verticalAlign="middle" gap="SP2">
            <Typography as="span">
              <TagIcon />
            </Typography>
            {preview.map((topic) => (
              <Topic active key={topic.id} onClick={handleOpen} topic={topic} />
            ))}
            <Show if={!!rest.length}>
              <Typography variant="p2-14" as="span">
                <TextButton onClick={handleOpen} variant="secondary">
                  + {rest.length}
                </TextButton>
              </Typography>
            </Show>
          </Stack>
        ) : (
          <TextButton
            onClick={handleOpen}
            prefixIcon={<PlusIcon />}
            variant="secondary"
          >
            {t('groups-web.discussion.topics.modal.open-button')}
          </TextButton>
        )}
      </Popover>
      <TopicsDialog
        isOpen={isMobile && isOpen}
        onClose={handleClose}
        {...props}
      />
    </>
  );

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }
}

TopicsSelect.displayName = 'TopicsSelect';
