import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  ensureDraftContent,
  fromDraft,
} from 'ricos-content/dist/lib/converters';
import { getText } from 'ricos-content/libs/content-application';

import { selectCurrentUser, selectDraft } from 'store/selectors';

import { Button } from 'wui/Button';
import { Card } from 'wui/Card';
import { Box } from 'wui/Box';
import { CardContent } from 'wui/CardContent';
import { CardActionArea } from 'wui/CardActionArea';
import { CardHeader } from 'wui/CardHeader';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';
import { Divider } from 'wui/Divider';
import { Avatar } from 'wui/Avatar';
import {
  Camera as CameraIcon,
  Video as VideoIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import settingsParams from 'Group/settingsParams';

import { NEW_POST_BOX } from './dataHooks';
import classes from './CreatePost.scss';

export interface ICreatePostProps {
  className?: string;

  onClick(): void;
}

export function CreatePost(props: ICreatePostProps) {
  const { onClick } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const user = useSelector(selectCurrentUser);
  const draft = useSelector(selectDraft);

  const draftText = useMemo(
    () =>
      draft
        ? getText(fromDraft(ensureDraftContent(draft)))
            .join(' ')
            .trim()
        : undefined,
    [draft],
  );

  const placeholder =
    settings.get(settingsParams.newPostBoxLabel) ||
    t('groups-web.discussion.create-post.placeholder');

  if (isMobile) {
    return (
      <Card data-hook={NEW_POST_BOX} sideBorders={false}>
        <CardContent>
          <Button fullWidth onClick={onClick}>
            {t('groups-web.discussion.new-post.cta')}
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card padding="SP0" gap="SP0" data-hook={NEW_POST_BOX}>
      <CardActionArea
        gap="SP0"
        onClick={onClick}
        aria-label={t('groups-web.discussion.new-post.cta')}
      >
        <CardHeader
          padding="SP5"
          avatar={<Avatar src={user.imageUrl} lettersLimit={2} />}
          title={
            <TextField
              disabled
              tabIndex={-1}
              inputClassName={classes.input}
              value={draftText || placeholder}
              className={cls(classes.textField, {
                [classes.empty]: !draftText,
              })}
            />
          }
          action={<Button>{t('groups-web.discussion.new-post.cta')}</Button>}
        />
        <Divider />
      </CardActionArea>
      <Box
        padding="SP2 SP0"
        align="space-between"
        className={classes.pluginBar}
      >
        <Box width="100%" align="center">
          <TextButton
            variant="secondary"
            onClick={onClick}
            prefixIcon={<CameraIcon />}
          >
            {t('groups-web.discussion.new-post.plugins.image')}
          </TextButton>
        </Box>

        <Divider orientation="vertical" />

        <Box width="100%" align="center">
          <TextButton
            variant="secondary"
            onClick={onClick}
            prefixIcon={<VideoIcon />}
          >
            {t('groups-web.discussion.new-post.plugins.video')}
          </TextButton>
        </Box>

        <Divider orientation="vertical" />

        <Box width="100%" align="center">
          <TextButton variant="secondary" onClick={onClick}>
            GIF
          </TextButton>
        </Box>
      </Box>
    </Card>
  );
}

CreatePost.displayName = 'CreatePost';
