import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';

import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';

import { Groups } from './Groups';

import { FeedWithTitle } from '../FeedWithTitle';

export function GridLayout() {
  const { isMobile } = useEnvironment();

  if (isMobile) {
    return (
      <Box gap="SP0" direction="vertical">
        <Header />
        <PendingGroups />
        <Groups />
        <FeedWithTitle />
      </Box>
    );
  }

  return (
    <Box gap="SP6" direction="vertical">
      <Header />
      <PendingGroups />
      <Groups />
      <FeedWithTitle />
    </Box>
  );
}

GridLayout.displayName = 'GridLayout';
