import React from 'react';
import { useSelector } from 'react-redux';
import { DraftContent } from 'ricos-content';
import { truncateContent } from 'ricos-content/libs/truncateContent';
import { WixRicosViewer, WixRicosViewerProps } from '@wix/ricos-viewer';
import { extractUoUParams } from '@wix/ricos-common/libs/extractUoUParams';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { selectUserToken } from 'store/selectors';
import { TextButton } from 'wui/TextButton';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentProps } from './types';
import { useHelpers } from './hooks/useHelpers';
import { useRicosTheme } from './useTheme';

function truncate(content: DraftContent) {
  return truncateContent(content, {
    /**
     * Web have more space, so `wordsCount` and `blocksCount` limits increased compared to mobile:
     * https://github.com/wix-private/wix-one-groups/blob/a4398d31e2607fce67e82613b808766512020f3c/src/feed/components/rich-content-viewer.tsx#L19
     */
    wordsCount: 150,
    maxPlugins: 2,
    blocksCount: 6,
  });
}
export interface RichContentViewerProps
  extends Omit<WixRicosViewerProps, 'biSettings' | 'instance'>,
    RichContentProps {
  handleUserValidationRequest?(resolve: Function, reject: Function): void;
  groupId?: string;
  disablePreview?: boolean;
  showSeeMore?: boolean;
  usage: 'About' | 'FeedItem' | 'CustomTab';
}

export const RichContentViewer: React.FC<RichContentViewerProps> = (props) => {
  const { preset, usage, className, contentId, showSeeMore } = props;

  const biLogger = useBi();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { language } = useEnvironment();
  const theme = useRicosTheme();

  const instance = useSelector(selectUserToken);

  const pluginSelector = viewerPluginSelector(
    preset!,
    useHelpers({
      groupId: props.groupId,
    }),
  );

  const [shouldTruncateContent, setShouldTruncateContent] =
    React.useState(true);

  const defaultBIParams = extractUoUParams({ biLogger });

  const truncatedContent = React.useMemo(() => {
    if (showSeeMore && props.content) {
      return truncate(props.content);
    }

    return {
      content: props.content,
      isTruncated: false,
    };
  }, [truncate, showSeeMore, props.content]);

  // TODO: move SeeMore functionality inside RicosViewer
  const ricosContent = React.useMemo(() => {
    if (showSeeMore && truncatedContent?.isTruncated && shouldTruncateContent) {
      return truncatedContent?.content;
    }

    return props.content;
  }, [
    truncatedContent,
    shouldTruncateContent,
    showSeeMore,
    props.content,
  ]) as DraftContent;

  const handleTruncateLabelClick = React.useCallback(() => {
    setShouldTruncateContent(!shouldTruncateContent);
  }, [setShouldTruncateContent, shouldTruncateContent]);

  return (
    <div>
      <WixRicosViewer
        {...props}
        theme={theme}
        content={ricosContent}
        instance={instance}
        plugins={pluginSelector.plugins}
        locale={language}
        linkSettings={{
          rel: { nofollow: true, ugc: true },
          anchorTarget: '_blank',
        }}
        wixExperiments={experiments}
        biSettings={{
          consumer: 'Groups',
          platform: 'Livesite',
          contentId,
          usage,
          defaultParams: defaultBIParams,
        }}
      />

      {showSeeMore && truncatedContent.isTruncated && (
        <TextButton wired variant="link" onClick={handleTruncateLabelClick}>
          {shouldTruncateContent
            ? t('groups-web.discussion.feed.see-more')
            : t('groups-web.discussion.feed.see-less')}
        </TextButton>
      )}
    </div>
  );
};

RichContentViewer.displayName = 'RichContentViewer';
RichContentViewer.defaultProps = {
  preset: PRESETS.VIEWER,
  disablePreview: false,
};
