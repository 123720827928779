import { useSelector } from 'react-redux';

import { SortOrder } from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import { queryGroupMembers } from 'api/members';

import { useRouter } from 'router/react';
import { useHttpClient } from 'common/hooks/useHttpClient';

import { selectGroupSlugById } from 'store/selectors';

export const EVERYONE_MENTION_GUID = 'everyone';
export const EVERYONE_MENTION_DISPLAY_NAME = 'Everyone';

type Mention = {
  id: string;
  name: string;
  avatar?: string;
  type?: 'GROUP';
};

export function useMentions(groupId: string, isComments?: boolean) {
  const router = useRouter();

  const slug = useSelector(selectGroupSlugById(groupId));

  const httpClient = useHttpClient();

  return {
    getMentions,
    onMentionClick,
  };

  function onMentionClick(mention: Mention) {
    const isEveryone =
      mention.id === EVERYONE_MENTION_GUID || mention.type === 'GROUP';

    if (isEveryone) {
      router.go('group.members', { slug });
    } else {
      router.go('members.profile', { memberId: mention.id });
    }
  }

  async function getMentions(query: string) {
    const { data } = await httpClient.request(
      queryGroupMembers(groupId, {
        paging: {
          limit: 20,
        },
        sort: [
          {
            fieldName: 'profile.nickname',
            order: SortOrder.ASC,
          },
        ],
        filter: {
          'profile.nickname': {
            $contains: query,
          },
        },
      }),
    );

    const members: Mention[] = data.members.map((member) => ({
      id: member.memberId as string,
      name: member.name,
      avatar: member.imageUrl,
    }));

    if (
      EVERYONE_MENTION_DISPLAY_NAME.toLowerCase().includes(query.toLowerCase())
    ) {
      members.unshift({
        type: 'GROUP',
        id: isComments ? groupId : EVERYONE_MENTION_GUID,
        name: EVERYONE_MENTION_DISPLAY_NAME,
      });
    }

    return members;
  }
}
