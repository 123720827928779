import { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

/**
 * TODO get rid of this
 *
 * @deprecated
 */
export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error(
      '`useTheme()` must be used within a `ThemeContextProvider`',
    );
  }

  return context;
}
