import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { IFeedItem } from 'api/feed/types';
import { selectGroupSlugById, selectIsGroupSecret } from 'store/selectors';

import {
  Comment as CommentIcon,
  Share as ShareIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { useHref } from 'router/react';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { ShareDialog } from 'wui/ShareDialog';
import { Divider } from 'wui/Divider';
import { CardContent } from 'wui/CardContent';
import { TextButton } from 'wui/TextButton';

import { groupSettingsParams } from 'settings/group';

import { Reactions } from './Reactions';

interface IProps {
  item: IFeedItem;

  openCommentForm(): void;
}

export function FeedItemActions(props: IProps) {
  const { item, openCommentForm } = props;

  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const slug = useSelector(selectGroupSlugById(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));

  const feedItemUrl = useHref(
    'group.discussion.post',
    { slug, feedItemId },
    { absolute: true },
  );

  const [shareDialogOpened, setShareDialogOpened] = useState(false);

  return (
    <CardContent gap="SP2" disableSideGutters={isMobile}>
      <Divider />
      <Box align="space-between" verticalAlign="middle">
        <Box width="100%" align="center">
          <Reactions item={item} />
        </Box>
        <Box width="100%" align="center">
          <TextButton
            variant="secondary"
            prefixIcon={<CommentIcon />}
            onClick={openCommentForm}
          >
            {t('groups-web.feed.feed-item.social-bar.comment')}
          </TextButton>
        </Box>

        <Show if={!isGroupSecret && Boolean(item.permissions?.canShare)}>
          <Wire
            cssVarName="showShareButton--inline-block"
            legacyFallback={settings.get(groupSettingsParams.showShareButton)}
          >
            <Box width="100%" align="center">
              <TextButton
                variant="secondary"
                prefixIcon={<ShareIcon />}
                onClick={openShareDialog}
              >
                {t('groups-web.feed.feed-item.social-bar.share')}
              </TextButton>
            </Box>
          </Wire>
        </Show>
      </Box>
      <Divider />

      <ShareDialog
        data={{ url: feedItemUrl }}
        isOpen={shareDialogOpened}
        canUseNative={isMobile}
        onClose={closeShareDialog}
        title={t('groups-web.discussion.feed.post-actions.share.dialog')}
      />
    </CardContent>
  );

  function openShareDialog() {
    setShareDialogOpened(true);
  }

  function closeShareDialog() {
    setShareDialogOpened(false);
  }
}

FeedItemActions.displayName = 'FeedItemActions';
