import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/loadable/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/loadable/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginLinkButton } from 'wix-rich-content-plugin-button/loadable/viewer';
import {
  initSpoilersContentState,
  pluginSpoiler,
  SpoilerViewerWrapper,
} from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginLineSpacing } from 'wix-rich-content-plugin-line-spacing/viewer';
import { PRESETS } from '../types';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { PluginSelector } from './PluginSelector';

const viewerPluginMap = {
  [PluginTypes.Image]: pluginImage,
  [PluginTypes.Giphy]: pluginGiphy,
  [PluginTypes.FileUpload]: pluginFileUpload,
  [PluginTypes.Emoji]: pluginEmoji,
  [PluginTypes.Mention]: pluginMentions,
  [PluginTypes.ExternalMentions]: pluginMentions,
  [PluginTypes.ExternalLink]: pluginLink,
  [PluginTypes.LinkPreview]: pluginLinkPreview,
  [PluginTypes.VerticalEmbed]: pluginVerticalEmbed,
  [PluginTypes.Divider]: pluginDivider,
  [PluginTypes.HTML]: pluginHtml,
  [PluginTypes.TextColor]: pluginTextColor,
  [PluginTypes.TextHighlight]: pluginTextHighlight,
  [PluginTypes.LinkButton]: pluginLinkButton,
  [PluginTypes.Poll]: pluginPoll,
  [PluginTypes.Gallery]: pluginGallery,
  [PluginTypes.Video]: pluginVideo,
  [PluginTypes.Spoiler]: pluginSpoiler,
  [PluginTypes.LineSpacing]: pluginLineSpacing,
};
export const viewerPluginSelector = (preset: PRESETS, helpers: any) => {
  helpers[PluginTypes.Spoiler] = {
    initSpoilersContentState,
    SpoilerViewerWrapper,
  };
  return new PluginSelector(viewerPluginMap, preset, helpers);
};
