import { groupSettingsParams, IGroupSettingsParams } from 'settings/group';
import {
  appearanceSettingsParams,
  IAppearanceSettingsParams,
} from 'settings/appearance';

export type ISettingsParams = IGroupSettingsParams & IAppearanceSettingsParams;

export default {
  ...appearanceSettingsParams,
  ...groupSettingsParams,
} as ISettingsParams;
