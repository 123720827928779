import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IFeedItem, ReactionMode } from 'api/feed/types';
import { useController } from 'common/context/controller';

import { ReactionType, Reactions as TPAReactions } from 'wix-ui-tpa/cssVars';

import { ADDITIONAL_REACTIONS } from './constants';
import { selectUserReaction } from './selectors';
import * as helpers from './helpers';

interface IProps {
  item: IFeedItem;
}

export function Reactions(props: IProps) {
  const { item } = props;

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const canReact = item.permissions?.canReact;

  const { t } = useTranslation();
  const { feed$, application$ } = useController();

  const userReaction = useSelector(selectUserReaction(feedItemId));
  const [selectedId, setSelectedId] = useState(
    helpers.toReactionType(userReaction?.code as string),
  );

  useEffect(() => {
    setSelectedId(helpers.toReactionType(userReaction?.code as string));
  }, [userReaction?.code]);

  return (
    <TPAReactions
      data-hook="reactions"
      selectedId={selectedId}
      popoverTriggerAriaLabel="Reactions"
      onReactionSelect={handleReactionSelect}
      additionalReactions={ADDITIONAL_REACTIONS}
      reactionLabel={handleGetReactionLabel}
      reactionIcon={handleGetReactionIcon}
      defaultReaction="thumbsup"
      theme="light"
    />
  );

  function handleReactionSelect(id: ReactionType) {
    if (selectedId === id || !id) {
      unreact(id || (selectedId as ReactionType));
    } else {
      react(id);
    }
  }

  function react(id: ReactionType) {
    if (!canReact) {
      application$.showDialog({
        dialog: 'joinGroup',
        params: { groupId },
      });

      return;
    }

    const code = helpers.fromReactionType(id);

    setSelectedId(id);
    feed$.react(groupId, feedItemId, code, ReactionMode.REPLACE);
  }

  function unreact(id: ReactionType) {
    const code = helpers.fromReactionType(id);

    setSelectedId(undefined);
    feed$.unreact(groupId, feedItemId, code);
  }

  function handleGetReactionLabel(id: ReactionType) {
    return t(helpers.getReactionLabel(id));
  }

  function handleGetReactionIcon(id: ReactionType) {
    return helpers.getReactionIcon(id) as React.ReactElement;
  }
}

Reactions.displayName = 'Reactions';
