import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { feedSettingsParams, feedStylesParams } from 'settings/feed';
import { groupsSettingsParams } from 'settings/groups';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { Wire } from 'wui/Wire';

import { CentralFeed } from '../../CentralFeed';

import classes from './FeedWithTitle.scss';

export function FeedWithTitle() {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();

  const showFeed = settings.get(feedSettingsParams.showFeed);

  const { htmlTag } = styles.get(feedStylesParams.feedTitleFont);

  if (isMobile) {
    return (
      <Show if={showFeed}>
        <Box className={cls(classes.root, classes.mobile)}>
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={
              settings.get(feedSettingsParams.showFeedTitle) || isMobile
            }
          >
            <Typography
              as={htmlTag}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={settings.get(groupsSettingsParams.headerAlignment)}
            >
              {settings.get(feedSettingsParams.feedTitle)}
            </Typography>
          </Wire>
        </Box>
        <CentralFeed className={classes.feed} />
      </Show>
    );
  }

  return (
    <Show if={showFeed}>
      <Box align={settings.get(feedSettingsParams.feedAlignment)}>
        <Box direction="vertical" className={classes.root}>
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={settings.get(feedSettingsParams.showFeedTitle)}
          >
            <Typography
              as={htmlTag}
              variant="h2-20"
              id="feed-title"
              className={classes.title}
              align={settings.get(groupsSettingsParams.headerAlignment)}
            >
              {settings.get(feedSettingsParams.feedTitle)}
            </Typography>
          </Wire>
          <CentralFeed className={classes.feed} />
        </Box>
      </Box>
    </Show>
  );
}

FeedWithTitle.displayName = 'FeedWithTitle';
