import React, { useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  Facebook as FacebookIcon,
  Link as LinkIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { CopyUrlButton, IconButton } from 'wix-ui-tpa/cssVars';

import { Box } from '../Box';
import { Show } from '../Show';
import { Drawer } from '../Drawer';
import { AlertDialog } from '../AlertDialog';
import { DialogTitle } from '../DialogTitle';
import { DialogContent } from '../DialogContent';

interface IProps extends React.ComponentProps<typeof AlertDialog> {
  data: ShareData;
  title?: string;
  canUseNative?: boolean;
}

export function ShareDialog(props: IProps) {
  const { canUseNative, title, data, ...rest } = props;

  const { isMobile, isSSR } = useEnvironment();

  const useNative = !isSSR && !!navigator.share && canUseNative;

  useEffect(() => {
    if (!props.isOpen || !useNative) {
      return;
    }

    navigator.share(data).finally(() => props.onClose?.());
  }, [props.isOpen]);

  if (useNative) {
    return null;
  }

  const url = encodeURIComponent(data.url as string);

  const facebookUrl = `//facebook.com/sharer/sharer.php?u=${url}` as const;
  const twitterUrl = `//twitter.com/intent/tweet?url=${url}` as const;
  const linkedInUrl =
    `//linkedin.com/sharing/share-offsite/?url=${url}` as const;

  if (isMobile) {
    return (
      <Drawer {...rest}>
        <Box padding="SP9 SP6" gap="SP5" direction="vertical">
          <Show if={!!title}>
            <DialogTitle alert title={title} />
          </Show>
          <Box gap="SP5" align="center" verticalAlign="middle">
            <IconButton as="a" icon={<FacebookIcon />} href={facebookUrl} />
            <IconButton as="a" icon={<TwitterIcon />} href={twitterUrl} />
            <IconButton as="a" icon={<LinkedInIcon />} href={linkedInUrl} />
            <CopyUrlButton
              icon={<LinkIcon />}
              url={data.url as string}
              successText="Link Copied"
              tooltipText={undefined as any}
            />
          </Box>
        </Box>
      </Drawer>
    );
  }

  return (
    <AlertDialog {...rest}>
      <Show if={!!title}>
        <DialogTitle alert title={title} />
      </Show>
      <DialogContent alert>
        <Box align="space-between" verticalAlign="middle">
          <IconButton
            as="a"
            icon={<FacebookIcon size={32} />}
            href={facebookUrl}
          />
          <IconButton
            as="a"
            icon={<TwitterIcon size={32} />}
            href={twitterUrl}
          />
          <IconButton
            as="a"
            icon={<LinkedInIcon size={32} />}
            href={linkedInUrl}
          />
          <CopyUrlButton
            icon={<LinkIcon size={32} />}
            url={data.url as string}
            successText="Link Copied"
            tooltipText={undefined as any}
          />
        </Box>
      </DialogContent>
    </AlertDialog>
  );
}

ShareDialog.displayName = 'wui/ShareDialog';
ShareDialog.defaultProps = {
  canUseNative: true,
};
