import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Modal } from '../Modal';
import { Direction, Slide } from '../Slide';
import { Paper } from '../Paper';
import { IconButton } from '../IconButton';
import { DIALOG_CROSS_ICON } from '../Dialog/dataHooks';
import {
  Close as CloseIcon,
  CloseSmall as CloseIconSmall,
} from '@wix/wix-ui-icons-common/on-stage';

import classes from './Drawer.scss';

interface IDrawerProps
  extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  bw?: boolean;
  anchor?: Direction;
  children?: React.ReactNode;
  hideCloseButton?: boolean;

  paperProps?: React.ComponentProps<typeof Paper>;
}

export function Drawer(props: IDrawerProps) {
  const { anchor, hideCloseButton, bw, paperProps } = props;

  const { isMobile } = useEnvironment();

  return (
    <Modal focusTrap={!isMobile} {...props}>
      <Slide appear direction={anchor} in={props.isOpen} timeout={300}>
        <Paper
          bw={bw}
          shadow={false}
          sideBorders={false}
          topBottomBorders={false}
          {...paperProps}
          className={cls(classes.paper, paperProps?.className, {
            [classes.mobile]: isMobile,
            [classes.left]: anchor === 'left',
            [classes.right]: anchor === 'right',
            [classes.top]: anchor === 'top',
            [classes.bottom]: anchor === 'bottom',
          })}
        >
          {!hideCloseButton && (
            <IconButton
              aria-label="close"
              className={classes.close}
              onClick={props.onClose}
              icon={isMobile ? <CloseIconSmall /> : <CloseIcon />}
              data-hook={DIALOG_CROSS_ICON}
            />
          )}

          {props.children}
        </Paper>
      </Slide>
    </Modal>
  );
}

Drawer.displayName = 'wui/Drawer';
Drawer.defaultProps = {
  hideCloseButton: true,
  anchor: 'bottom',
  bw: true,
};
