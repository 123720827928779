import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { useRouter } from 'router/react';
import { useDidUpdate } from 'common/hooks';
import settingsParams from 'Groups/settingsParams';
import { LayoutType } from 'settings/consts';

import { GridLayout } from './GridLayout';
import { ListLayout } from './ListLayout';
import { SidebarLayout } from './SidebarLayout';

export function Layout() {
  const router = useRouter();
  const settings = useSettings();
  const layout = settings.get(settingsParams.groupListLayout);
  const sortBy = settings.get(settingsParams.sortBy);

  useDidUpdate(() => {
    router.reload();
  }, [layout, sortBy]);

  switch (layout) {
    case LayoutType.grid:
      return <GridLayout />;

    case LayoutType.list:
      return <ListLayout />;

    default:
    case LayoutType.sideBar:
      return <SidebarLayout />;
  }
}

Layout.displayName = 'Layout';
