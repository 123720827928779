import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { EFilterKeys } from 'api/feed/constants';
import { useCurrentStateAndParams } from 'router/react';

import { Button } from 'wui/Button';
import { EmptyState } from 'wui/EmptyState';

interface IProps extends React.ComponentProps<typeof EmptyState> {
  onCreatePost(): void;
}

export function EmptyResults(props: IProps) {
  const { onCreatePost, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  if (params[EFilterKeys.TOPICS]) {
    return (
      <EmptyState
        title={t('groups-web.discussion.filters.empty-result.title')}
        subtitle={t('groups-web.discussion.filters.empty-result.subtitle')}
        action={
          <Button onClick={onCreatePost}>
            {t('groups-web.discussion.filters.empty-result.button')}
          </Button>
        }
        {...rest}
      />
    );
  }

  return (
    <EmptyState
      title={t('groups-web.discussion.first.title')}
      subtitle={t('groups-web.discussion.first.description')}
      action={
        <Button onClick={onCreatePost}>
          {t('groups-web.discussion.filters.empty-result.button')}
        </Button>
      }
    />
  );
}

EmptyResults.displayName = 'EmptyResults';
