import { Experiments } from '@wix/yoshi-flow-editor';
import { ToolbarType, ViewerPlugin } from 'wix-rich-content-common';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import {
  LinkPreviewProviders,
  PluginMap,
  PRESETS,
  VerticalEmbedProviders,
  VideoProviders,
} from '../types';
import { RCEConfigBuilder } from 'common/components/RichContent/RCEConfigBuilder';

type Plugin = {
  name: PluginTypes;
  enabledByExperiments?: string[];
};

const postEditorPlugins: Plugin[] = [
  { name: PluginTypes.Image },
  { name: PluginTypes.Gallery },
  { name: PluginTypes.Video },
  { name: PluginTypes.Giphy },
  { name: PluginTypes.FileUpload },
  { name: PluginTypes.Emoji },
  { name: PluginTypes.Mention },
  { name: PluginTypes.ExternalLink },
  { name: PluginTypes.Divider },
  { name: PluginTypes.Poll },
  {
    name: PluginTypes.LinkButton,
  },
  {
    name: PluginTypes.VerticalEmbed,
  },
  // TODO: enable after ricos fix
  // PluginTypes.History,
  { name: PluginTypes.LinkPreview },
  {
    name: PluginTypes.HTML,
  },
  {
    name: PluginTypes.TextColor,
  },
  {
    name: PluginTypes.TextHighlight,
  },
  {
    name: PluginTypes.Headings,
  },
  {
    name: PluginTypes.Spoiler,
  },
  {
    name: PluginTypes.LineSpacing,
  },
];

const presets: { [key in PRESETS]: Plugin[] } = {
  [PRESETS.EDITOR]: postEditorPlugins,
  [PRESETS.VIEWER]: [
    { name: PluginTypes.Image },
    { name: PluginTypes.Gallery },
    { name: PluginTypes.Video },
    { name: PluginTypes.Giphy },
    { name: PluginTypes.FileUpload },
    { name: PluginTypes.Emoji },
    { name: PluginTypes.Poll },
    { name: PluginTypes.Mention },
    { name: PluginTypes.ExternalLink },
    { name: PluginTypes.LinkPreview },
    { name: PluginTypes.LinkButton },
    { name: PluginTypes.VerticalEmbed },
    { name: PluginTypes.TextColor },
    { name: PluginTypes.TextHighlight },
    { name: PluginTypes.Headings },
    { name: PluginTypes.Divider },
    { name: PluginTypes.Spoiler },
    { name: PluginTypes.HTML },
    { name: PluginTypes.LineSpacing },
  ],
};

const pluginsToDisplayInMorePlusFooterToolbar: PluginTypes[] = [
  PluginTypes.LinkButton,
  PluginTypes.VerticalEmbed,
  PluginTypes.LinkPreview,
  PluginTypes.HTML,
];

export class PluginSelector {
  public plugins: ViewerPlugin[];
  public pluginsToDisplayInFooterToolbar: PluginTypes[];
  private experiments: Experiments;

  constructor(
    private pluginMap: PluginMap,
    private preset: PRESETS,
    protected helpers: any,
  ) {
    this.experiments = helpers.experiments;
    const config = this.getPluginsConfig();
    this.plugins = this.getAllAvailablePlugins().map((plugin) => {
      // @ts-expect-error
      return pluginMap[plugin.name](config[plugin.name]);
    });
    this.pluginsToDisplayInFooterToolbar =
      this.getPluginsToDisplayInFooterToolbar();
  }

  public getPluginsToDisplayInFooterToolbar(): PluginTypes[] {
    return this.getAllAvailablePlugins()
      .filter(
        (plugin) =>
          !pluginsToDisplayInMorePlusFooterToolbar.includes(plugin.name),
      )
      .map((plugin) => plugin.name);
  }

  private getAllAvailablePlugins(): Plugin[] {
    return presets[this.preset].filter(
      (plugin) => this.isPluginEnabled(plugin) && this.pluginMap[plugin.name],
    );
  }

  private isPluginEnabled(plugin: Plugin): boolean {
    if (plugin.enabledByExperiments) {
      return plugin.enabledByExperiments.some((experiment) =>
        this.experiments.enabled(experiment),
      );
    }
    return true;
  }

  private getPluginsConfig() {
    return {
      [PluginTypes.Giphy]: {
        insertToolbars: [ToolbarType.SIDE, ToolbarType.FOOTER],
        toolbar: {
          hidden: ['separator1'],
        },
      },
      [PluginTypes.Image]: {
        toolbar: {
          hidden: ['separator1'],
        },
        disableExpand: false,
      },
      [PluginTypes.Poll]: {
        ...this.helpers[PluginTypes.Poll],
        voteRole: 'SITE_MEMBERS',
        showVoteRoleSetting: false,
      },
      [PluginTypes.Gallery]: {
        accept: 'image/*',
        toolbar: {
          hidden: ['separator2'],
        },
      },
      [PluginTypes.FileUpload]: {
        toolbar: {
          hidden: ['separator', 'separator1', 'separator2', 'separator3'],
        },
        downloadTarget: '_blank',
      },
      [PluginTypes.Video]: {
        toolbar: {
          hidden: ['separator1'],
        },
        exposeButtons: this.getVideoExposeButtons(),
      },
      [PluginTypes.Mention]: {
        ...this.helpers[PluginTypes.Mention],
      },
      [PluginTypes.LinkPreview]: {
        exposeEmbedButtons: this.getLinkPreviewExposeButtons(),
        enableEmbed: true,
        enableLinkPreview: true,
      },
      [PluginTypes.Emoji]: {},
      [PluginTypes.ExternalLink]: {},
      [PluginTypes.VerticalEmbed]: {
        exposeEmbedButtons: this.getVerticalEmbedExposeButtons(),
      },
      [PluginTypes.Spoiler]: {
        ...this.helpers[PluginTypes.Spoiler],
      },
    };
  }

  private getLinkPreviewExposeButtons(): string[] {
    const buttonsBuilder = new RCEConfigBuilder<string>(this.experiments);
    return buttonsBuilder
      .add(LinkPreviewProviders.Instagram)
      .add(LinkPreviewProviders.Twitter)
      .add(LinkPreviewProviders.TikTok)
      .build();
  }

  private getVideoExposeButtons(): string[] {
    const buttonsBuilder = new RCEConfigBuilder<string>(this.experiments);
    return buttonsBuilder
      .add(VideoProviders.Video)
      .add(VideoProviders.SoundCloud)
      .build();
  }

  private getVerticalEmbedExposeButtons(): string[] {
    const buttonsBuilder = new RCEConfigBuilder<string>(this.experiments);
    return buttonsBuilder
      .add(VerticalEmbedProviders.Event)
      .add(VerticalEmbedProviders.Booking)
      .add(VerticalEmbedProviders.Product)
      .build();
  }
}
